.annotationLayer {
  display: none;
}
@page {
  /* margin: 0mm; */
}
@page {
  size: auto;
  /* auto is the initial value */
  margin: 0mm 10mm 0mm 10mm;
  /* this affects the margin in the printer settings 最关键的还是这个*/
}
