
.uk-container-no-margin{
	display:block;
	margin:30px 30px;
}

.part1_block{

}

.part1_block_left{
	width:48.5%;
	float:left;
	color:#323333;
	font-size:36px;
	line-height:54px;
	padding-left:8%;
	padding-right:1.5%;
	text-align:justify;
}

.part1_block_right{
	width:42%;
	float:left;
	padding-top:4.5%;
}

.part1_block_right_m{
	display:none;
}

.part1_block_left h1{
	font-weight:bold;
	font-size:72px;
}

.h1_top{
	color:#ffffff;
	margin-bottom:0px;
	margin-left:-25px;
}

.h1_bottom{
	margin-top:0px;
	margin-bottom:40px;
}

.part3_block_left{
	width:60%;
	float:left;
	color:#323333;
	font-size:36px;
	line-height:54px;
	padding-left:3%;
	padding-right:3%;
	text-align:justify;
	margin-bottom:70px;
}

.part3_block_right{
	width:32%;
	float:left;
	padding-top:4.5%;
	padding-right:1%;
	text-align:right;
}

.part3_list{
	padding-left:10%;
	padding-right:10%;
	line-height:60px;
}

.part3_list .icon{
	margin-top:-8px;
}

.part_block_full{
	width:94%;
	padding-left:3%;
	padding-right:3%;
	color: #323333;
    font-size: 36px;
    line-height: 54px;
}

.part_block_full .desc{
	font-size: 22px;
    line-height: 34px;
	padding-top:30px;
	font-weight:bold;
	text-align:justify;
}

.part_block_full .reference{
	font-size: 22px;
    line-height: 34px;
	padding-top:30px;
	text-align:justify;
}

.part4_block_full{
	width:94%;
	padding-left:3%;
	padding-right:3%;
	color: #323333;
    font-size: 19px;
    line-height: 30px;
}

.part4_block_col4{
	/*
	width:25%;
	*/
	text-align:center;
	float:left;
}

.part4_block_col4_1{
	width:25%;
}

.part4_block_col4_2{
	width:20%;
}

.part4_block_col4_3{
	width:25%;
}

.part4_block_col4_4{
	width:30%;
}

.part4_block_col5_1{
	float:left;
	width:20%;
}

/* Testing Part */
.part2_block_left{
	float:left;
	width:38.5%;
	padding-left:1.5%;
}

.part2_block_right{
	float:left;
	width:49%;
	color: #323333;
	font-size: 36px;
	line-height: 54px;
	padding-left: 3%;
	padding-right: 8%;
	text-align: justify;
}

label{
	font-weight:bold;
	padding-left:15px;
	padding-right:15px;
}

label:hover{
	cursor:pointer;
}

.part_title{
	display:block;
	color:#45A4A2;
	margin-top:40px;
}

.part_start_btn{
	text-align:center;
	padding-top:20px;
	padding-bottom:50px;
}

.quiz_title{
	font-size:42px;
	font-weight:bold;
	margin-top: 10px;
	margin-bottom: 40px;
	color:#000000 !important;
}

.quiz_title span{
	color:#45A4A2;
}

.question_title{
	font-weight:bold;
	margin-bottom: 30px;
}

.answer_table td{
	padding:5px 15px;
}

.uk-checkbox, .uk-radio{
	/*
	background-color:#;
	*/
	width:35px;
	height:35px;
	border: 5px solid #46A5A3 !important;
	margin-top:-6px;
	color:#ffffff !important;
}

.uk-checkbox:checked, .uk-checkbox:indeterminate, .uk-radio:checked{
	background-color:#46A5A3 !important;
	
}

.step-item{
	float:left;
	width:20%;
	text-align:center;
}

.quiz_btn{
	/* background:url('./res/btn-bg.png') no-repeat; */
	background-position: 40px center;
	background-color:#46A5A3;
	color:#ffffff;
	border:solid #46A5A3 1px;
	border-radius:30px;
	padding:10px 40px 10px 90px;
	font-size:40px;
	outline: none;
}

.quiz_btn:hover{
	cursor:pointer;
}

.re_quiz_btn{
	/* background:url('./res/refresh-bg.png') no-repeat; */
	background-position: 40px center;
	background-color:#46A5A3;
	color:#ffffff;
	border:solid #46A5A3 1px;
	border-radius:30px;
	padding:10px 40px 10px 90px;
	font-size:40px;
	outline: none;
}

.re_quiz_btn:hover{
	cursor:pointer;
}

.pdf_btn{
	/* background:url('./res/download-btn-bg.png') no-repeat; */
	background-position: 40px center;
	background-color:#46A5A3;
	color:#ffffff;
	border:solid #46A5A3 1px;
	border-radius:30px;
	padding:10px 40px 10px 90px;
	font-size:28px;
	outline: none;
}

.pdf_btn:hover{
	cursor:pointer;
}

.uk-light .uk-slidenav{
	color:red;
}

.uk-slideshow-items{
	min-height:;
}

.uk-position-center-left{
	
	display:none;
	/**/
}

.uk-position-center-right{
	
	display:none;
	/**/
}

.uk-slideshow-nav{
	
	display:none;
	/**/
}

#quiz1_result1{
	background-color:#931919;
}

#quiz1_result2{
	background-color:#dd820a;
}

#quiz1_result3{
	background-color:#138702;
}

#quiz2_result1{
	background-color:#138702;
}

#quiz2_result2{
	background-color:#dd820a;
}

.quiz_result{
	/*
	padding:50px 40px;
	*/
	padding:35px 35px;
}

.quiz_result_title{
	color:#F1F091;
}

.quiz_result_content{
	color:#ffffff;
	/*
	font-size:34px;
	*/
}

.part_content_desc{
	
}

.testing-image-m{
	display:none;
	width:70%;
	margin:0 auto 15px;
}
/* End Testing Part */

@media (max-width:1700px){
	.uk-slideshow{
		max-height:720px;	
	}

	.part_title{
		display:block;
		color:#45A4A2;
		margin-top:20px;
	}
}

@media (max-width:1600px){
	.part1_block_left{
		font-size:30px;
		line-height:45px;
	}

	.part1_block_left h1{
		font-weight:bold;
		font-size:60px;
	}

	.part3_block_left{
		font-size:30px;
		line-height:45px;
	}

	.part2_block_right{
		font-size:30px;
		line-height:45px;
	}

	.part_block_full{
		font-size: 30px;
		line-height: 45px;
	}

	.part_block_full .desc{
		font-size: 18px;
		line-height: 30px;
	}

	.part_block_full .reference{
		font-size: 18px;
		line-height: 30px;
	}

	.part4_block_full{
		font-size: 16px;
		line-height: 28px;
	}

	.uk-slideshow{
		max-height:670px;	
	}

	.part_start_btn{
		padding-top:10px;
		padding-bottom:40px;
	}

	.quiz_title{
		font-size:36px;
		line-height:48px;
	}

	.quiz_btn, .re_quiz_btn{
		font-size:30px;
		background-size: 21px 30px;
		padding:10px 30px 10px 70px;
	}
}

@media (max-width:1500px){
	.uk-slideshow{
		max-height:630px;	
	}
}

@media (max-width:1400px){
	.part1_block_left{
		font-size:24px;
		line-height:36px;
	}

	.part1_block_left h1{
		font-weight:bold;
		font-size:48px;
	}

	.part3_block_left{
		font-size:24px;
		line-height:36px;
	}

	.part2_block_right{
		font-size:24px;
		line-height:36px;
	}

	.part_block_full{
		font-size: 24px;
		line-height: 36px;
	}

	.part_block_full .desc{
		font-size: 15px;
		line-height: 24px;
	}

	.part_block_full .reference{
		font-size: 15px;
		line-height: 24px;
	}

	.part4_block_full{
		font-size: 14px;
		line-height: 24px;
	}

	.part1_block_left{
		width:53.5%;
		padding-left:3%;
	}

	.uk-section{
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.uk-slideshow{
		max-height:530px;	
	}

	.quiz_title{
		font-size:32px;
		line-height:45px;
		margin-bottom:30px;
	}

	.quiz_result{
		padding:35px 30px;
	}
}

@media (max-width:1200px){
	.part1_block_left{
		font-size:20px;
		line-height:30px;
	}

	.part1_block_left h1{
		font-weight:bold;
		font-size:40px;
	}

	.part3_block_left{
		font-size:20px;
		line-height:30px;
	}
	
	.part2_block_right{
		font-size:20px;
		line-height:30px;
	}

	.part_block_full{
		font-size: 20px;
		line-height: 30px;
	}

	.part_block_full .desc{
		font-size: 13px;
		line-height: 20px;
	}

	.part_block_full .reference{
		font-size: 13px;
		line-height: 20px;
	}

	.part4_block_full{
		font-size: 13px;
		line-height: 20px;
	}

	.uk-slideshow{
		max-height:460px;	
	}

	.quiz_title{
		font-size:28px;
		line-height:42px;
		margin-bottom:25px;
	}

	.quiz_btn, .re_quiz_btn{
		font-size:24px;
		background-size: 14px 20px;
		padding:10px 20px 10px 60px;
		background-position: 30px center;
	}

	.part2_block_right{
		width:55%;
		padding-right:2%;
	}
}

@media (max-width:1024px){
	.part1_block_left{
		font-size:18px;
		line-height:27px;
	}

	.part1_block_left h1{
		font-weight:bold;
		font-size:36px;
	}

	.part3_block_left{
		font-size:18px;
		line-height:27px;
	}

	.part2_block_right{
		font-size:18px;
		line-height:27px;
	}

	.part_block_full{
		font-size: 18px;
		line-height: 27px;
	}
	/*
	.part_block_full .desc{
		font-size: 13px;
		line-height: 20px;
	}

	.part_block_full .reference{
		font-size: 13px;
		line-height: 20px;
	}

	.part4_block_full{
		font-size: 13px;
		line-height: 20px;
	}
	*/
	.uk-section{
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.part3_list{
		padding-left: 5%;
		padding-right: 5%;
		line-height: 50px;
	}

	.uk-slideshow{
		max-height:400px;	
	}
	
	.part_title{
		margin-top:0px;
	}
	
	.question_title{
		margin-bottom: 20px;
	}

	.part_start_btn{
		padding-top:0px;
		padding-bottom:20px;
	}

	.quiz_title{
		font-size:24px;
		line-height:40px;
		margin-bottom:15px;
	}

	.quiz_result{
		padding:15px 30px;
	}

	.part4_block_col5_1{
		width:33%;
	}
}

@media (max-width:768px){
	.uk-container-no-margin{
		margin:15px;
	}

	.part1_block_left{
		font-size:18px;
		line-height:27px;
		margin-bottom:20px;
	}

	.part1_block_left h1{
		font-weight:bold;
		font-size:36px;
	}

	.part3_block_left{
		font-size:18px;
		line-height:27px;
		margin-bottom:40px;
	}
	
	.part2_block_right{
		font-size:18px;
		line-height:27px;
	}

	.part_block_full{
		font-size: 18px;
		line-height: 27px;
	}
	/*
	.part_block_full .desc{
		font-size: 13px;
		line-height: 20px;
	}

	.part_block_full .reference{
		font-size: 13px;
		line-height: 20px;
	}

	.part4_block_full{
		font-size: 13px;
		line-height: 20px;
	}
	*/
	.uk-section{
		padding-top: 25px;
		padding-bottom: 25px;
	}
	
	.part1_block_left{
		width:94%;
		padding-left:3%;
		padding-right:3%;
	}

	.part1_block_right_m{
		display:block;
		padding-top:0px;
		padding-bottom:25px;
	}

	.part1_block_right{
		display:none;
	}
	
	.part3_block_left{
		width:94%;
		padding-left:3%;
		padding-right:3%;
	}

	.part3_block_right{
		display:none;
	}

	.testing-image-m{
		display:block;
	}

	.part2_block_left{
		display:none;
	}

	.part2_block_right{
		width:94%;
		padding-left:3%;
		padding-right:3%;
		display:block;
		float:none;
	}

	.uk-slideshow{
		height:350px;
	}

	.quiz_result{
		padding:15px 30px;
	}

	.uk-checkbox, .uk-radio{
		width:25px;
		height:25px;
		margin-top:-3px;
	}

	label {
		padding-left: 5px;
		padding-right: 5px;
		font-size:16px;
	}

	.answer_table td{
		padding: 5px 5px;
	}

	.part3_list{
		padding-left: 1%;
		padding-right: 1%;
	}

	.part3_list .icon{
		margin-top:-3px;
		max-width:30px;
	}

	.part4_block_col5_1{
		width:50%;
	}
}

@media (max-width:680px){
	.uk-slideshow{
		height:450px;
	}

	.uk-slideshow-items{
		height:450px;
	}
}

@media (max-width:500px){
	.part1_block_left h1{
		font-weight:bold;
		font-size:30px;
		line-height:42px;
	}

	.uk-slideshow{
		height:480px;
	}

	.uk-slideshow-items{
		height:480px;
	}

	.quiz_result{
		padding:15px 15px;
	}

	.quiz_title{
		font-size:24px;
		line-height:36px;
		margin-bottom:15px;
	}

	.quiz_btn, .re_quiz_btn{
		font-size:18px;
		background-size: 14px 20px;
		padding:10px 20px 10px 45px;
		background-position: 20px center;
	}

	.quiz_title{
		font-size:22px;
		line-height:28px;
	}

	label {
		padding-left: 3px;
		padding-right: 3px;
	}

	.answer_table td{
		padding: 5px 0px;
	}

	.part_block_full{
		font-size: 15px;
		line-height: 20px;
	}
}

@media (max-width:450px){
	.uk-slideshow{
		height:430px;
	}

	.uk-slideshow-items{
		height:430px;
	}
}

@media (max-width:400px){
	.uk-slideshow{
		height:400px;
	}

	.uk-slideshow-items{
		height:400px;
	}
}

@media (max-width:1600px){
	.part4_block_col4 img{
		height:130px;
		width:auto;
	}
}

@media (max-width:1400px){
	.part4_block_col4 img{
		height:110px;
		width:auto;
	}
}

@media (max-width:1200px){
	.part4_block_col4 img{
		height:90px;
		width:auto;
	}
}

@media (max-width:1000px){
	.part4_block_col4 img{
		height:80px;
		width:auto;
	}
}

@media (max-width:960px){
	.part4_block_col4_1{
		width:50%;
		height:160px;
	}

	.part4_block_col4_2{
		width:50%;
		height:160px;
	}

	.part4_block_col4_3{
		width:50%;
		height:160px;
	}

	.part4_block_col4_4{
		width:50%;
		height:160px;
	}
	
	.part4_block_col4{
		vertical-align:middle;
	}

	.part4_block_col4 img{
		width:auto;
		height:auto;
	}

	.part4_block_col4_2 img{
		/*
		max-width:100px;
		*/
	}
}

@media (max-width:960px){
	.part4_block_col4_2 img{
		max-width:100px;
		/**/
	}
}


@media (max-width:768px){
	.part4_block_col4{
		height:120px;
	}
}


.uk-section-bottom{
	padding-top: 35px;
	padding-bottom: 35px;
}