.Mframe * {
  user-select: none;
  border: 0;
}
.slick-dots {
  opacity: 0;
}
.quiz_result {
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.transColor,
.part_title,
.step-item,
.quiz_result_pdf,
.uk-radio,
.pdf_btn,
.re_quiz_btn,
.quiz_btn,
h1 {
  filter: hue-rotate(164deg) saturate(5);
}
